import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IContentField, IPageContentWidget } from "~~/models/page.model";
import {
  DisplayOrientation,
  SportMenuLiveBet9CardLayoutMode,
} from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateFlex,
  generateFontSize,
  generateGrid,
  generateTextColor,
  generateWidth,
} from "../helpers";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";

import {
  generateMenu1LevelItemCssString,
  generateMenu2LevelItemCssString,
  generateSportsMenuWidgetSpacingVarsCssString,
} from "./sports-menu-prematch-fenix-css";
import { generateListTimeCssString } from "./sportsbook-live-widget-css";

const generateEventContainerCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const cardsGrid = generateGrid({
      templateColumns: `repeat(${field.options.columns}, minmax(0, 1fr))`,
      gap: field.options.distance,
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content:
        generateDefaultStyles(field.options) +
        cardsGrid +
        generateWidth(100, "%"),
    });

    return cssString;
  };

export const generateHeaderCssString =
  (field: IWidgetOptions, eventCardClass?: string) =>
  (cssString: string): string => {
    const headerFlex = generateFlex({
      flex: "flex",
      align: "center",
      gap: field.options.verticalSpace,
      justify: "unset",
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: headerFlex,
    });

    const isHeaderLayoutVertical =
      field.options.layout === DisplayOrientation.VERTICAL;

    const leagueNamePosition =
      field.options?.[
        `leagueName${
          isHeaderLayoutVertical ? "Vertical" : "Horizontal"
        }Position`
      ];

    const headerLeftFlex = generateFlex({
      flex: "flex",
      align: "center",
      gap: field.options.verticalSpace,
      justify: "unset",
      direction: isHeaderLayoutVertical
        ? leagueNamePosition === "top"
          ? "column"
          : "column-reverse"
        : leagueNamePosition === "left"
        ? "row"
        : "row-reverse",
    });

    cssString += generateCssClassWithContent({
      className: field.options._leftCssClass,
      content: `${headerLeftFlex}
        flex-grow: 1;
        max-width: 100%;
        overflow: hidden;
      `,
    });

    const headerBootomFlex = generateFlex({
      flex: "flex",
      align: "center",
      gap: field.options.verticalSpace,
      justify: "unset",
    });

    cssString += generateCssClassWithContent({
      className: field.options._bottomCssClass,
      content: `${headerBootomFlex}
        flex-shrink: 0;
        max-width: 100%;
        overflow: hidden;
        width: 100%;
      `,
    });

    cssString += generateDefaultStylesWithStates(
      field,
      false,
      undefined,
      undefined,
      undefined,
      eventCardClass,
      true
    );

    return cssString;
  };

export const generateFooterCssString =
  (field: IWidgetOptions, isLayoutFirstMode: boolean, cardCssClass?: string) =>
  (cssString: string): string => {
    const isFooterLayoutVertical =
      field.options.layout === DisplayOrientation.VERTICAL;

    const footerFlex = generateFlex({
      flex: "flex",
      align: "unset",
      gap: field.options.verticalSpace,
      justify: "unset",
      direction:
        !isLayoutFirstMode && isFooterLayoutVertical ? "column" : "row",
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: footerFlex,
    });

    const footerItemFlex = generateFlex(
      isLayoutFirstMode
        ? {
            flex: "flex",
            align: isFooterLayoutVertical ? "unset" : "center",
            gap: field.options.verticalSpace,
            justify: isFooterLayoutVertical ? "space-around" : "unset",
            direction: isFooterLayoutVertical ? "column" : "row",
          }
        : {
            flex: "flex",
            align: "center",
            gap: field.options.verticalSpace,
            justify: "unset",
          }
    );

    cssString += generateCssClassWithContent({
      className: field.options._itemCssClass,
      content: `${footerItemFlex}
        ${isLayoutFirstMode ? "" : "overflow: hidden; max-width: 100%;"}
      `,
    });

    if (isLayoutFirstMode) {
      cssString += generateCssClassWithContent({
        className: field.options._itemCssClass,
        pseudoClassName: ":first-child",
        content: "flex-grow: 1",
      });
    }

    if (!isLayoutFirstMode && !isFooterLayoutVertical) {
      cssString += generateCssClassWithContent({
        className: field.options._itemCssClass,
        pseudoClassName: ":last-child",
        content: "flex-direction: row-reverse",
      });
    }

    cssString += generateDefaultStylesWithStates(
      field,
      false,
      undefined,
      undefined,
      undefined,
      cardCssClass,
      true
    );

    return cssString;
  };

export const generateLeagueNameCssString =
  (field: IWidgetOptions, cardCssClass: string) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: `min-width: 3ch;
      width: ${field.options.displayMode === "fill" ? "100%" : "fit-content"}
      `,
    });

    cssString += generateDefaultStylesWithStates(
      field,
      false,
      undefined,
      undefined,
      undefined,
      cardCssClass,
      true
    );

    return cssString;
  };

export const generateFavouritesButtonCssString =
  (
    element: IWidgetOptions,
    headerElement?: IWidgetOptions,
    cardCssClass?: string
  ) =>
  (cssString: string): string => {
    const content = [
      generateFontSize(element.options.iconSize),
      `align-self: ${
        headerElement?.options?.favoriteIconAlignment || "unset"
      };`,
      "justify-self: flex-end;",
      "flex-shrink: 0;",
    ];
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: content.join(""),
    });

    cssString += generateDefaultStylesWithStates(
      element,
      false,
      undefined,
      undefined,
      undefined,
      cardCssClass,
      true
    );
    return cssString;
  };

export const generateEventNameCssString =
  (field: IWidgetOptions, isLayoutFirstMode: boolean, cardCssClass: string) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: `min-width: 3ch; ${isLayoutFirstMode ? "flex-grow: 1;" : ""}`,
    });

    cssString += generateDefaultStylesWithStates(
      field,
      false,
      undefined,
      undefined,
      undefined,
      cardCssClass,
      true
    );

    return cssString;
  };

export const generatePeriodCssString =
  (field: IWidgetOptions, cardCssClass: string) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: `width: ${
        field.options.displayMode === "fill" ? "100%" : "fit-content"
      }
      `,
    });

    cssString += generateDefaultStylesWithStates(
      field,
      false,
      undefined,
      undefined,
      undefined,
      cardCssClass,
      true
    );

    return cssString;
  };

const generateRacingCardCssString =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const flex = generateFlex({
      flex: "flex",
      align: "center",
      gap: "8",
      justify: "unset",
    });

    cssString += generateCssClassWithContent({
      className: field.options._cssClass,
      content: `${flex}
      overflow:hidden;
      max-width: 100%;
      `,
    });

    cssString += generateDefaultStylesWithStates(
      field,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      true
    );

    return cssString;
  };

export const generateTimeStatesCssString =
  (element: IWidgetField, eventCardClass: string) =>
  (cssString: string): string => {
    const { options } = element;

    cssString += generateCssClassWithContent({
      className: eventCardClass,
      content: generateTextColor(options.states.hover.icon.color),
      pseudoClassName: `:hover ${options.icon._cssClass}`,
    });

    cssString += generateCssClassWithContent({
      className: `${eventCardClass}[data-active="true"]`,
      content: generateTextColor(options.states.active.icon.color),
      pseudoClassName: ` .${options.icon._cssClass}`,
    });

    return cssString;
  };

const generateRacingEventStatusAdditionalCss =
  (element: IContentField) =>
  (cssString: string): string => {
    const { options } = element;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: "min-width:fit-content;",
    });

    return cssString;
  };

export const generateSportsMenuLiveFenixWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const title = widget.content["title"];
  const menu_level_1 = widget.content["menu_level_1"];
  const menu_level_2 = widget.content["menu_level_2"];
  const event_container = widget.content["event_container"];
  const event_card = widget.content["event_card"];
  const racing_event_card = widget.content["racing_event_card"];
  const header = widget.content["header"];
  const footer = widget.content["footer"];
  const event_league = widget.content["event_league"];
  const favourites_button = widget.content["favourites_button"];
  const racing_favourites_icon = widget.content["racing_favourites_icon"];
  const event_suspended_status = widget.content["event_suspended_status"];
  const racing_event_status = widget.content["racing_event_status"];
  const stream_icon = widget.content["stream_icon"];
  const racing_stream_icon = widget.content["racing_stream_icon"];
  const event_team_player = widget.content["event_team_player"];
  const racing_event_name = widget.content["racing_event_name"];
  const event_score = widget.content["event_score"];
  const event_time = widget.content["event_time"];
  const event_period = widget.content["event_period"];
  const racing_event_timer = widget.content["racing_event_timer"];

  const isLayoutFirstMode =
    event_card.options.layoutMode === SportMenuLiveBet9CardLayoutMode.FIRST;

  widget.options._cssClass = generateClassName("SportsMenuLiveFenixWidget");

  menu_level_1.options._cssClass = generateClassName("menu_level_1");
  menu_level_1.options._rootCssClass = generateClassName("root-menu_level_1");
  menu_level_1.options._linkCssClass = generateClassName("link-menu_level_1");
  menu_level_1.options._contentCssClass = generateClassName(
    "content-menu_level_1"
  );

  menu_level_2.options._cssClass = generateClassName("menu_level_2");
  menu_level_2.options._rootCssClass = generateClassName("root-menu_level_2");
  menu_level_2.options._linkCssClass = generateClassName("link-menu_level_2");
  menu_level_2.options._contentCssClass = generateClassName(
    "content-menu_level_2"
  );

  title.options._cssClass = generateClassName("title");

  menu_level_1.options.itemIcon._cssClass = generateClassName("itemIcon");
  menu_level_1.options.arrowSettings._cssClass =
    generateClassName("arrowSettings");

  if (menu_level_2.options.itemIcon) {
    menu_level_2.options.itemIcon._cssClass = generateClassName("itemIcon");
  }

  if (menu_level_2.options.arrowSettings) {
    menu_level_2.options.arrowSettings._cssClass =
      generateClassName("arrowSettings");
  }

  const titleCss = title ? [generateStringDefault(title)] : [];

  event_container.options._cssClass = generateClassName("eventContainer");
  event_card.options._cssClass = generateClassName("eventCard");
  racing_event_card.options._cssClass = generateClassName("racingEventCard");
  header.options._cssClass = generateClassName("header");
  header.options._leftCssClass = generateClassName("headerLeft");
  header.options._bottomCssClass = generateClassName("headerBottom");
  footer.options._cssClass = generateClassName("footer");
  footer.options._itemCssClass = generateClassName("footerItem");
  event_league.options._cssClass = generateClassName("eventLeague");
  favourites_button.options._cssClass = generateClassName("favouritesButton");
  racing_favourites_icon.options._cssClass = generateClassName(
    "racingfavouritesButton"
  );
  event_suspended_status.options._cssClass = generateClassName(
    "eventSuspendedStatus"
  );
  racing_event_status.options._cssClass =
    generateClassName("racingEventStatus");
  stream_icon.options._cssClass = generateClassName("streamIcon");
  racing_stream_icon.options._cssClass = generateClassName("racingStreamIcon");
  event_team_player.options._cssClass = generateClassName("eventTeamPlayer");
  racing_event_name.options._cssClass = generateClassName("racingEventName");
  event_score.options._cssClass = generateClassName("eventScore");
  event_time.options._cssClass = generateClassName("eventTime");
  event_time.options._containerCssClass = generateClassName(
    "event_time_container"
  );
  event_time.options.icon._cssClass = generateClassName("evenTimeIcon");
  event_period.options._cssClass = generateClassName("eventPeriod");
  racing_event_timer.options._cssClass = generateClassName("racingEventTimer");

  return pipeSync<string>(
    generateStringDefault(widget),
    generateMenu1LevelItemCssString(menu_level_1 as IWidgetField),
    generateMenu2LevelItemCssString(
      menu_level_2 as IWidgetField,
      undefined,
      false
    ),
    generateSportsMenuWidgetSpacingVarsCssString(widget.options),
    generateEventContainerCssString(event_container),
    generateStringWithStates(event_card, false, undefined, true),
    generateRacingCardCssString(racing_event_card),
    generateHeaderCssString(header, event_card.options._cssClass),
    generateFooterCssString(
      footer,
      isLayoutFirstMode,
      event_card.options._cssClass
    ),
    generateLeagueNameCssString(event_league, event_card.options._cssClass),
    generateFavouritesButtonCssString(
      favourites_button,
      header,
      event_card.options._cssClass
    ),
    generateFavouritesButtonCssString(
      racing_favourites_icon,
      undefined,
      racing_event_card.options._cssClass
    ),
    generateStringWithStates(
      event_suspended_status,
      false,
      event_card.options._cssClass,
      true
    ),
    generateStringWithStates(
      racing_event_status,
      false,
      racing_event_card.options._cssClass,
      true
    ),
    generateStringWithStates(
      racing_event_timer,
      false,
      racing_event_card.options._cssClass,
      true
    ),
    generateRacingEventStatusAdditionalCss(racing_event_status),
    generateStringWithStates(
      stream_icon,
      false,
      event_card.options._cssClass,
      true
    ),
    generateStringWithStates(
      racing_stream_icon,
      false,
      racing_event_card.options._cssClass,
      true
    ),
    generateEventNameCssString(
      event_team_player,
      isLayoutFirstMode,
      event_card.options._cssClass
    ),
    generateEventNameCssString(
      racing_event_name,
      true,
      racing_event_card.options._cssClass
    ),
    generateStringWithStates(
      event_score,
      false,
      event_card.options._cssClass,
      true
    ),
    generateListTimeCssString(event_time as IWidgetField),
    generatePeriodCssString(event_period, event_card.options._cssClass),
    generateTimeStatesCssString(
      event_time as IWidgetField,
      event_card.options._cssClass
    ),
    ...titleCss,
    generateStringDefault(racing_event_timer)
  )("");
};
