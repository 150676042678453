import { CSSProperties } from "vue";

import { ElementStyle } from "~~/models/common";
import {
  getBackgroundColorStyle,
  getBorderStyle,
  getBoxShadowStyle,
  getCornerRadiusStyle,
  getSpacing,
  getColorStyle,
  getFontSizeStyle,
  getDecorationValue,
  getFontFamilyStyle,
  getTextAlignStyle,
} from "~~/assets/utils/widget-settings";
import {
  Grid,
  ResizingType,
  SpacingKeyName,
  State,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";

import { getPxValueFromNumber } from "..";

export const getCommonStyles = (
  options: IWidgetOptions,
  state = State.DEFAULT
): ElementStyle => {
  const optionsWithStates =
    state === State.DEFAULT ? options : options.states?.[state];

  const spacing = options.spacing || {};

  const border = getBorderStyle(optionsWithStates?.border || options.border);
  const shadow = getBoxShadowStyle(optionsWithStates?.shadow || options.shadow);
  const cornerRadius = getCornerRadiusStyle(options.cornerRadius);
  const margin = getSpacing(
    spacing.margin || options.margin || options.margins,
    SpacingKeyName.MARGIN
  );
  const padding = getSpacing(
    spacing.padding || options.padding,
    SpacingKeyName.PADDING
  );
  const background = getBackgroundColorStyle(
    optionsWithStates?.fill || options.fill
  );

  return {
    ...margin,
    ...padding,
    ...border,
    ...shadow,
    ...cornerRadius,
    ...background,
  };
};

export const getTextCommonStyles = (
  options: IWidgetOptions,
  state = State.DEFAULT
): ElementStyle => {
  const optionsWithStates =
    state === State.DEFAULT ? options : options.states?.[state];
  return {
    ...getColorStyle(optionsWithStates.color),
    ...getFontSizeStyle(optionsWithStates.theme),
    ...getDecorationValue(optionsWithStates.decoration),
    ...getFontFamilyStyle(optionsWithStates.fontFamily),
    ...getTextAlignStyle(optionsWithStates.alignment),
  };
};

export const getDisplayGridStyles = (
  gridSettings: IWidgetOptions,
  styles: CSSProperties = {}
): ElementStyle => {
  if (!gridSettings) return {};

  const minCardWidth = getPxValueFromNumber(gridSettings.minCardWidth);
  const gap = getPxValueFromNumber(
    gridSettings.gap?.column ?? gridSettings.gap
  );
  const rowGap = getPxValueFromNumber(
    gridSettings.gap?.row ?? gridSettings.rowGap
  );

  const fixedGridType =
    gridSettings.gridFixedType === Grid.FILL ? "auto-fill" : "auto-fit";

  const getGridTemplateColumns = (): string => {
    switch (gridSettings.grid) {
      case Grid.FIT:
      case ResizingType.FIT:
        return `repeat(auto-fit, minmax(${minCardWidth}, 1fr))`;

      case Grid.FILL:
      case ResizingType.FILL:
        return `repeat(auto-fill, minmax(${minCardWidth}, 1fr))`;

      case Grid.FIXED:
      case ResizingType.FIXED:
        return `repeat(${fixedGridType}, minmax(min((100% / ${gridSettings.minCardsInRow}) - ${gap}, max(${minCardWidth}, (100% / ${gridSettings.maxCardsInRow}) - ${gap})), 1fr))`;

      default:
        return "";
    }
  };

  return {
    display: "grid",
    gridAutoRows: "1fr",
    gap: gap,
    rowGap: rowGap,
    gridTemplateColumns: getGridTemplateColumns(),
    ...styles,
  };
};
