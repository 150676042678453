import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { ContainerMode } from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import { generateFlex, generateGrid, generateWidth } from "../helpers";
import { prefillWithClasses } from "../utils/prefill-with-classes";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateIconCssString,
  generateIconSizeCssString,
  generateStageButtonCssString,
} from "../utils/form-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import { getPxValueFromNumber } from "../..";

import {
  generateGameCardsStyles,
  generateGameCoverStyles,
  generateGamesWrapperStyles,
  generateHeaderStyles,
  generateNoGamesDescriptionStyles,
  generatePageTitleCssString,
  generateWidgetStyles,
} from "./casino-games-list-fenix-widget-css";
import {
  generateFavouriteIconCssString,
  generateHoverContentStyles,
  generateTagStyles,
} from "./casino-games-list-widget-css";
import { generateGridLayoutCssString } from "./promotions-list-widet-css";

export const generateAllGamesCardStyles =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = element;

    const flex = generateFlex({
      flex: "flex",
      align: "center",
      justify: options.alignment,
      direction: "column",
      gap: options.distance,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: flex + generateDefaultStyles(options) + "height:100%;",
    });

    return cssString;
  };

const generateGameCardsListStyles =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = element;
    const isSlider = options.mode === ContainerMode.CAROUSEL;

    if (isSlider) {
      const grid = generateGrid({
        gap: options.gridSettings.gap.column,
        autoRows: getPxValueFromNumber(options.gridSettings.minCardWidth),
        gridAutoFlow: "column",
      });

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: grid + "overflow: auto; overflow-y:hidden;",
      });
    } else {
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: generateWidth(100, "%") + generateDefaultStyles(options),
      });

      cssString += generateGridLayoutCssString(element)("");
    }

    return cssString;
  };

const generateHoverContentAdditionalStyles =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = element;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: "height:100%",
    });

    return cssString;
  };

const generatePageTitleAdditionalCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = element;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: `justify-content:${options.alignment} !important;`,
    });

    return cssString;
  };

export const generateCasinoSmartGamesListWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName(widget.name);
  widget.options._wrapperCssClass = generateClassName(`${widget.name}-wrapper`);
  const prefilled = prefillWithClasses(widget.content);

  prefilled.title.options.icon._cssClass = generateClassName("page_title_icon");

  prefilled.nogames_title.options.description._cssClass = generateClassName(
    "nogames_description"
  );

  prefilled.play_free_button.options.iconSettings._cssClass = generateClassName(
    "play_free_button_icon"
  );

  prefilled.play_money_button.options.iconSettings._cssClass =
    generateClassName("play_money_button_icon");

  prefilled.games_button.options.iconSettings._cssClass =
    generateClassName("games_button_icon");

  prefilled.header_button.options.iconSettings._cssClass =
    generateClassName("header_button_icon");

  return pipeSync<string>(
    generateWidgetStyles(widget.options),
    generatePageTitleCssString(prefilled.title),
    generateIconCssString(prefilled.title.options.icon, "textColor"),
    generateIconSizeCssString(prefilled.title.options.icon, "iconSize"),
    generateStringDefault(prefilled.game_name),
    generateAllGamesCardStyles(prefilled.all_games_card),
    // generateCustomStyles(prefilled.search_result.options._cssClass, {
    //   width: "100%",
    // }),

    generateHeaderStyles(prefilled.header),

    generateGamesWrapperStyles(prefilled.games_list),

    generatePageTitleAdditionalCssString(prefilled.title),

    generateStageButtonCssString(prefilled.games_button as IWidgetField),
    generateStageButtonCssString(prefilled.header_button as IWidgetField),

    generateStringDefault(prefilled.nogames_title),

    generateNoGamesDescriptionStyles(prefilled.nogames_title),

    generateGameCardsListStyles(prefilled.games_list),

    generateGameCardsStyles(prefilled.game_cards),

    generateGameCoverStyles(prefilled.game_cover, "casino-game-card"),
    generateHoverContentStyles(
      prefilled.hover_content,
      prefilled.game_cover,
      "btn-group"
    ),
    generateHoverContentAdditionalStyles(prefilled.game_cover),

    generateStringWithStates(prefilled.game_title, true),
    generateStageButtonCssString(
      prefilled.play_money_button as IWidgetField,
      false,
      generateWidth(100, "%")
    ),
    generateStageButtonCssString(prefilled.back_button as IWidgetField),
    generateStageButtonCssString(prefilled.show_more_button as IWidgetField),
    generateStageButtonCssString(
      prefilled.play_free_button as IWidgetField,
      false,
      generateWidth(100, "%")
    ),

    generateFavouriteIconCssString(prefilled.favorite_icon),
    generateTagStyles(prefilled.promo_tag)
  )("");
};
