import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import {
  generateCssClassWithContent,
  generateDefaultStylesWithStates,
} from "~~/assets/utils/widget-css/compiler/default-css-compiler";
import {
  generateDisplayOrientation,
  generateFontSize,
  generateSize,
  generateTextColor,
  generateWidth,
} from "~~/assets/utils/widget-css/helpers";
import { getPxValueFromNumber } from "~~/assets/utils";
import { Align } from "~~/models/widgets/widget-controls.model";
import { CellSizeType } from "~~/models/grid.interface";

import { generateStringDefault } from "../utils/pipe-helper-functions";
import { generateClassName } from "../utils/generate-class-name";

export const generatePopularLeaguesWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const title = widget.content["title"];
  const menu_level_1 = widget.content["menu_level_1"];
  const no_events_message = widget.content["no_events_message"];

  widget.options._cssClass = generateClassName("PopularLeaguesWidget");
  title.options._cssClass = generateClassName("title");
  menu_level_1.options.items._cssClass = generateClassName("items");
  menu_level_1.options.elements._cssClass = generateClassName("elements");
  menu_level_1.options._cssClass = generateClassName("menu_level_1");
  menu_level_1.options.itemIcon._cssClass = generateClassName("itemIcon");
  no_events_message.options._cssClass = generateClassName("no_events_message");

  const menuLevel1Options = menu_level_1.options;
  const menuLevel1ClassName = menuLevel1Options._cssClass;
  const menuLevel1States = menuLevel1Options.states;

  return pipeSync<string>(
    generateStringDefault(widget),
    generateTitle(),
    generateItems(),
    generateElements(),
    generateItemIcon(),
    generateMenuLevel1(),
    generateStringDefault(no_events_message)
  )("");

  function generateTitle() {
    if (title.options?._active) {
      return generateStringDefault(title);
    }

    return (cssString: string): string => {
      return cssString;
    };
  }

  function generateItems() {
    return (cssString: string): string => {
      cssString += generateCssClassWithContent({
        className: menuLevel1Options.items._cssClass,
        content: [
          generateDisplayOrientation(
            menuLevel1Options.items.displayOrientation
          ),
          `gap:${getPxValueFromNumber(menuLevel1Options.items.gap)};`,
        ].join(""),
      });
      return cssString;
    };
  }

  function generateElements() {
    return (cssString: string): string => {
      cssString += generateCssClassWithContent({
        className: menuLevel1Options.elements._cssClass,
        content: [
          generateDisplayOrientation(
            menuLevel1Options.elements.displayOrientation
          ),
          `align-items:${Align.MIDDLE};`,
          `flex-wrap:no-wrap;`,
          `gap:${getPxValueFromNumber(menuLevel1Options.elements.gap)};`,
          `justify-content:${menuLevel1Options.baseDisplaySettings.alignment};`,
          generateSize(menuLevel1Options.size),
          menuLevel1Options.size?.width?.type === CellSizeType.HUG
            ? "width:fit-content;"
            : "",
        ].join(""),
      });

      cssString += generateCssClassWithContent({
        className: menuLevel1Options.elements._cssClass,
        childClassName: "accordion-item-element__title",
        content: [generateWidth(100, "%")].join(""),
      });

      return cssString;
    };
  }

  function generateItemIcon() {
    return (cssString: string): string => {
      if (menuLevel1Options.itemIcon?._active) {
        const itemIconClassName = menuLevel1Options.itemIcon._cssClass;

        cssString += generateCssClassWithContent({
          className: itemIconClassName,
          content:
            generateTextColor(menuLevel1Options.itemIcon.color) +
            generateFontSize(menuLevel1Options.itemIcon.size),
        });

        for (const stateName in menuLevel1States) {
          cssString += generateCssClassWithContent({
            className: menuLevel1ClassName,
            pseudoClassName: `:${stateName} .${itemIconClassName}`,
            content: generateTextColor(
              menuLevel1States[stateName].itemIcon.color
            ),
          });
        }
      }

      return cssString;
    };
  }

  function generateMenuLevel1() {
    return (cssString: string): string => {
      cssString += generateDefaultStylesWithStates(menu_level_1, false);

      return cssString;
    };
  }
};
