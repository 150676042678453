<!-- eslint-disable vue/valid-v-slot -->
<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <TransactionHistoryFenixWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div
      :class="`transaction-history--${states.page_type}`"
      class="transaction-history"
    >
      <WidgetField
        v-if="fields.title.active"
        :widget="widget"
        :field="fields.title.field"
        class="transaction-history__field"
      >
        <BasicTitleField
          :field="fields.title.field"
          :value="fields.title.field.options.text[states.page_type]"
        />
      </WidgetField>

      <WidgetField
        v-if="states.page_type === State.PENDING_WITHDRAWALS"
        :widget="widget"
        :field="fields.pending_description.field"
      >
        <BasicTitleField :field="fields.pending_description.field" />
      </WidgetField>

      <WidgetField
        v-if="states.page_type !== State.PENDING_WITHDRAWALS"
        :widget="widget"
        :field="fields.filter.field"
        no-overflow
      >
        <BasicDesignWrapperField
          :widget="widget"
          :field="fields.filter.field"
        >
          <WidgetField
            v-if="fields.filter_title.active"
            :widget="widget"
            :field="fields.filter_title.field"
            class="transaction-history__field"
          >
            <BasicTitleField :field="fields.filter_title.field" />
          </WidgetField>

          <WidgetField
            :widget="widget"
            :field="fields.form.field"
          >
            <TransactionHistoryFenixFilter
              :widget="widget"
              :field="fields.form.field"
              :fields="filterFields"
              :states="states"
            />
          </WidgetField>
        </BasicDesignWrapperField>
      </WidgetField>

      <!--
        New
       -->
      <WidgetField
        :widget="widget"
        :field="fields.table.field"
        :is-custom-selected="isTableFieldSelected"
      >
        <BasicDesignWrapperField
          :widget="widget"
          :field="fields.table.field"
        >
          <div :style="{ ...tableContainerVars, ...checkboxStyle }">
            <RacingSportsCards
              v-if="states.items === State.ACTIVE_EVENTS"
              :widget="widget"
              :table-header="TABLE_HEADER"
              :rows="TABLE_CONTENT"
              :field="fields.table.field"
              :header-field="fields.table_header_settings.field"
              :rows-field="fields.table_row_settings.field"
              :mode="fields.table.field.options.mode"
              :grid="true"
              :class="`transaction-history__table--${fields.table.field.options.mode}`"
            >
              <template #checkbox-title>
                <FormCheckboxField
                  :widge="widget"
                  :styling-fields="[fields.table_checkboxes_settings.field]"
                  :states="states"
                  class="transaction-history__checkbox-container"
                  value=""
                  state-name="table_checkboxes_settings"
                  custom-field-name="table_checkboxes_settings"
                />
              </template>

              <template #checkbox="{ row }">
                <WidgetField
                  :widget="widget"
                  :field="fields.table_data.field"
                >
                  <BasicDesignWrapperField
                    :widget="widget"
                    :field="fields.table_data.field"
                  >
                    <div
                      :class="`transaction-history__data--${fields.table.field.options.mode}`"
                      class="transaction-history__data"
                      :style="checkboxStyle"
                    >
                      <FormCheckboxField
                        :widge="widget"
                        :styling-fields="[
                          fields.table_checkboxes_settings.field,
                        ]"
                        :states="states"
                        class="transaction-history__checkbox-container"
                        value=""
                        state-name="table_checkboxes_settings"
                        custom-field-name="table_checkboxes_settings"
                      />

                      <WidgetField
                        :widget="widget"
                        :field="fields.table_date.field"
                        class="transaction-history__date"
                      >
                        <BasicTitleField :field="fields.table_date.field">{{
                          row.date
                        }}</BasicTitleField>
                      </WidgetField>

                      <WidgetField
                        :widget="widget"
                        :field="fields.table_reference.field"
                        class="transaction-history__reference"
                        :style="referenceStyles"
                      >
                        <BasicTitleField
                          :field="fields.table_reference.field"
                          class="transaction-history__col-text"
                        >
                          <span> {{ row.reference }} </span>
                          <span :style="iconRefenceStyle">
                            <CommonIcon
                              :size="
                                fields.table_reference.field.options.icon.size
                              "
                              :name="
                                fields.table_reference.field.options.icon.value
                              "
                            /> </span
                        ></BasicTitleField>
                      </WidgetField>
                    </div>
                  </BasicDesignWrapperField>
                </WidgetField>
              </template>

              <template
                v-if="states.page_type !== 'pending_withdrawals'"
                #description="{ row }"
              >
                <WidgetField
                  :widget="widget"
                  :field="fields.table_description.field"
                >
                  <BasicTitleField :field="fields.table_description.field">{{
                    row.description
                  }}</BasicTitleField>
                </WidgetField>
              </template>

              <template
                v-if="states.page_type === 'pending_withdrawals'"
                #description="{ row }"
              >
                <WidgetField
                  :widget="widget"
                  :field="fields.table_description.field"
                >
                  <BasicTitleField :field="fields.table_description.field">{{
                    row.provider
                  }}</BasicTitleField>
                </WidgetField>
              </template>

              <template
                v-if="states.page_type === 'transaction_history'"
                #transaction_type="{ row }"
              >
                <WidgetField
                  :widget="widget"
                  :field="fields.table_parameters.field"
                >
                  <BasicDesignWrapperField
                    :widget="widget"
                    :field="fields.table_parameters.field"
                    class="transaction-history__parametrs"
                    :class="`transaction-history__parametrs--${fields.table.field.options.mode}`"
                  >
                    <WidgetField
                      :widget="widget"
                      :field="fields.table_transaction_type.field"
                      class="transaction-history__transaction-type"
                    >
                      <BasicTitleField
                        :field="fields.table_transaction_type.field"
                        >{{ row.transaction_type }}</BasicTitleField
                      >
                    </WidgetField>

                    <div class="transaction-history__amount">
                      <WidgetField
                        :widget="widget"
                        :field="fields.table_amount.field"
                        :style="amountStyles"
                      >
                        <BasicTitleField
                          v-if="fields.table_amount.field.options.label._active"
                          :field="fields.table_amount.field"
                          :options="fields.table_amount.field.options.label"
                          >Amount:</BasicTitleField
                        >

                        <BasicTitleField
                          :field="fields.table_amount.field"
                          :options="fields.table_amount.field.options.value"
                          >{{ row.amount }}</BasicTitleField
                        >
                      </WidgetField>
                    </div>

                    <div class="transaction-history__status">
                      <WidgetField
                        :widget="widget"
                        :field="fields.table_status.field"
                        class="transaction-history__col-text"
                      >
                        <BasicTitleField :field="fields.table_status.field">
                          <span
                            :class="`transaction-history__col-status--${row.status}`"
                            class="transaction-history__col-status"
                          >
                          </span>

                          <span class="transaction-history__status-text">
                            {{ row.status ? "Won" : "Lost" }}
                          </span></BasicTitleField
                        >
                      </WidgetField>
                    </div>
                  </BasicDesignWrapperField>
                </WidgetField>
              </template>

              <template
                v-if="states.page_type === 'betting_history'"
                #status="{ row }"
              >
                <WidgetField
                  :widget="widget"
                  :field="fields.table_parameters.field"
                >
                  <BasicDesignWrapperField
                    :widget="widget"
                    :field="fields.table_parameters.field"
                    class="transaction-history__parametrs"
                    :class="`transaction-history__parametrs--${fields.table.field.options.mode}`"
                  >
                    <div class="transaction-history__status">
                      <WidgetField
                        :widget="widget"
                        :field="fields.table_status.field"
                        class="transaction-history__col-text"
                      >
                        <BasicTitleField :field="fields.table_status.field">
                          <span
                            :class="`transaction-history__col-status--${row.status}`"
                            class="transaction-history__col-status"
                          >
                          </span>

                          <span class="transaction-history__status-text">
                            {{ row.status ? "Won" : "Lost" }}
                          </span></BasicTitleField
                        >
                      </WidgetField>
                    </div>

                    <div class="transaction-history__amount">
                      <WidgetField
                        :widget="widget"
                        :field="fields.table_amount.field"
                        :style="amountStyles"
                      >
                        <BasicTitleField
                          v-if="fields.table_amount.field.options.label._active"
                          :field="fields.table_amount.field"
                          :options="fields.table_amount.field.options.label"
                          >Amount:</BasicTitleField
                        >

                        <BasicTitleField
                          :field="fields.table_amount.field"
                          :options="fields.table_amount.field.options.value"
                          >{{ row.amount }}</BasicTitleField
                        >
                      </WidgetField>
                    </div>

                    <p>{{ row.winning }}</p>
                  </BasicDesignWrapperField>
                </WidgetField>
              </template>

              <template
                v-if="states.page_type === 'pending_withdrawals'"
                #operation_amount="{ row }"
              >
                <WidgetField
                  :widget="widget"
                  :field="fields.table_parameters.field"
                >
                  <BasicDesignWrapperField
                    :widget="widget"
                    :field="fields.table_parameters.field"
                    class="transaction-history__parametrs"
                    :class="`transaction-history__parametrs--${fields.table.field.options.mode}`"
                  >
                    <p>{{ row.amount }}</p>
                  </BasicDesignWrapperField>
                </WidgetField>
              </template>
            </RacingSportsCards>
            <BasicDesignWrapperField
              v-else
              :widget="widget"
              :field="fields.no_events_title.field"
              :style="{ margin: 0, padding: 0 }"
            >
              <BasicTitleField :field="fields.no_events_title.field" />
            </BasicDesignWrapperField>
          </div>
        </BasicDesignWrapperField>
      </WidgetField>

      <template v-if="states.page_type === State.PENDING_WITHDRAWALS">
        <WidgetField
          :widget="widget"
          :field="fields.pending_error_text.field"
          :style="statusMessageStyles"
        >
          <CommonIcon
            :style="statusMessageIconStyles"
            :name="fields.pending_error_text.field?.options.icon.value"
          />

          <span :style="statusMessageTextStyles">{{
            localizedStatusValue
          }}</span>
        </WidgetField>

        <WidgetField
          :widget="widget"
          :field="fields.action_button.field"
        >
          <BasicButtonField
            :field="fields.action_button.field"
            :current-state="states.action_button"
            class="transaction-history__cancel-button"
          />
        </WidgetField>
      </template>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { ICell, ICellDimensionValue } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useInit } from "~~/composables/widgets/transaction-history/fenix/useTransactionHistoryFenixInitData";
import {
  DisplayOrientation,
  ResizingType,
  State,
} from "~~/models/widgets/widget-controls.model";
import { ElementStyle } from "~~/models/common";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import {
  getColorStyle,
  getFontSizeStyle,
  getFlexPosition,
  getColorFromHex,
  getCornerRadiusStyle,
} from "~~/assets/utils/widget-settings";
import { useStates } from "~~/composables/widgets/common/useStates";
import { createCustomField } from "~~/assets/utils/widget";
import { FormStyleFieldName } from "~~/models/widgets/form.model";
import { useWidgetsStore } from "~~/store/widgets";
import { STYLING_FIELDS } from "~~/constants/widget-details/form/dynamic-fields/styling";
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import { getPxValueFromNumber } from "~~/assets/utils";
import { generateFlexAlignment } from "~~/assets/utils/widget-css/helpers";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { getStylesAsVars } from "~~/assets/utils/widget/form";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);
const widgetsSettingsStore = useWidgetSettingsStore();
const { selectedWidget, selectedField } = storeToRefs(widgetsSettingsStore);
const widgetsStore = useWidgetsStore();

const { t } = useI18n();

const {
  states,
  filterFields,
  DEFAULT_STATES,

  init,
} = useInit(props.widget);

const TABLE_HEADER = computed(() => {
  let header = null;
  switch (states.value.page_type) {
    case State.BETTING_HISTORY:
      header = [
        {
          label: "",
          name: "checkbox",
          gridArea: "checkbox",
        },
        {
          label: t("widgets.TransactionHistoryWidget.date"),
          name: "date",
          gridArea: "date",
        },
        {
          label: t("widgets.TransactionHistoryWidget.reference"),
          name: "reference",
          gridArea: "reference",
        },
        {
          label: t("widgets.TransactionHistoryWidget.description"),
          name: "description",
          gridArea: "description",
        },
        {
          label: t("widgets.TransactionHistoryWidget.status"),
          name: "status",
          gridArea: "transaction_type",
        },
        {
          label: t("widgets.TransactionHistoryWidget.operation_amount"),
          name: "operation_amount",
          gridArea: "operation_amount",
        },
        {
          label: t("widgets.TransactionHistoryWidget.status_winner"),
          name: "winning",
          gridArea: "status",
        },
      ];
      break;
    case State.PENDING_WITHDRAWALS:
      header = [
        {
          label: "",
          name: "checkbox",
          gridArea: "checkbox",
        },
        {
          label: t("widgets.TransactionHistoryWidget.date"),
          name: "date",
          gridArea: "date",
        },
        {
          label: t("widgets.TransactionHistoryWidget.reference"),
          name: "reference",
          gridArea: "reference",
        },
        {
          label: t("widgets.TransactionHistoryWidget.provider"),
          name: "provider",
          gridArea: "description",
        },
        {
          label: t("widgets.TransactionHistoryWidget.operation_amount"),
          name: "operation_amount",
          gridArea: "operation_amount",
        },
      ];
      break;
    case State.TRANSACTION_HISTORY:
    default:
      header = [
        {
          label: "",
          name: "checkbox",
          gridArea: "checkbox",
        },
        {
          label: t("widgets.TransactionHistoryWidget.date"),
          name: "date",
          contentWidth: "0",
          gridArea: "date",
        },
        {
          label: t("widgets.TransactionHistoryWidget.reference"),
          name: "reference",
          contentWidth: "0",
          gridArea: "reference",
        },
        {
          label: t("widgets.TransactionHistoryWidget.description"),
          name: "description",
          gridArea: "description",
        },
        {
          label: t("widgets.TransactionHistoryWidget.transaction_type"),
          name: "transaction_type",
          gridArea: "transaction_type",
        },
        {
          label: "Amount",
          name: "operation_amount",
          contentWidth: "0",
          gridArea: "operation_amount",
        },
        {
          label: t("widgets.TransactionHistoryWidget.status"),
          name: "status",
          contentWidth: "0",
          gridArea: "status",
        },
      ];
      break;
  }
  return header;
});

const TABLE_CONTENT = computed(() => {
  let content = null;
  switch (states.value.page_type) {
    case State.BETTING_HISTORY:
      content = [
        {
          date: "14-07-2023 15:01",
          reference: "Unique transaction id",
          description: "Description of what transaction has happened",
          status: true,
          amount: "+ 1,07",
          winning: "0,00",
        },
        {
          date: "14-07-2023 15:01",
          reference: "Unique transaction id",
          description: "Description of what transaction has happened",
          status: true,
          amount: "+ 1,07",
          winning: "0,00",
        },
        {
          date: "14-07-2023 15:01",
          reference: "Unique transaction id",
          description: "Description of what transaction has happened",
          status: false,
          amount: "- 2,25",
          winning: "0,00",
        },
      ];
      break;
    case State.PENDING_WITHDRAWALS:
      content = [
        {
          date: "14-07-2023 15:01",
          reference: "Unique transaction id",
          provider: "Description of what transaction has happened",
          amount: "0,00",
        },
        {
          date: "14-07-2023 15:01",
          reference: "Unique transaction id",
          provider: "Description of what transaction has happened",
          amount: "0,00",
        },
        {
          date: "14-07-2023 15:01",
          reference: "Unique transaction id",
          provider: "Description of what transaction has happened",
          amount: "0,00",
        },
      ];
      break;
    case State.TRANSACTION_HISTORY:
    default:
      content = [
        {
          date: "14-07-2023 15:01",
          reference: "Unique transaction id",
          description: "Description of what transaction has happened",
          transaction_type: "Describes the transaction type",
          status: false,
          amount: "+ 1,07",
        },
        {
          date: "14-07-2023 15:01",
          reference: "Unique transaction id",
          description: "Description of what transaction has happened",
          transaction_type: "Describes the transaction type",
          status: true,
          amount: "+ 1,07",
        },
        {
          date: "14-07-2023 15:01",
          reference: "Unique transaction id",
          description: "Description of what transaction has happened",
          transaction_type: "Describes the transaction type",
          status: false,
          amount: "+ 1,07",
        },
      ];
      break;
  }
  return content;
});

const statusMessageStyles = computed<ElementStyle>(() => {
  const field = fields.value.pending_error_text.field;

  if (!field) return {};

  return {
    ...getCommonStyles(field.options),
    display: "flex",
    gap: getPxValueFromNumber(field.options.distance),
    flexDirection:
      field.options.layout === DisplayOrientation.VERTICAL ? "column" : "row",
    alignItems:
      field.options.layout === DisplayOrientation.HORIZONTAL
        ? field.options.verticalAlignment
        : generateFlexAlignment(field.options.horizontalAlignment),
  };
});

const statusMessageIconStyles = computed<ElementStyle>(() => {
  const field = fields.value.pending_error_text.field;

  if (!field) return {};

  return {
    ...getColorStyle(field.options.icon.textColor),
    ...getFontSizeStyle(field.options.icon.iconSize),
  };
});

const statusMessageTextStyles = computed<ElementStyle>(() => {
  const field = fields.value.pending_error_text.field;

  if (!field) return {};

  return {
    ...getTextCommonStyles(field.options),
    flexGrow: 1,
    width: "100%",
  };
});

const localizedStatusValue = computed(() => {
  return useLocalizedValue().getLocalizedValue.value(
    fields.value.pending_error_text.field.value
  );
});

const isTableFieldSelected = computed<boolean>(() => {
  const tableFields = [
    fields.value.table.field,
    fields.value.table_header_settings.field,
    fields.value.table_row_settings.field,
    fields.value.table_checkboxes_settings.field,
  ];

  if (selectedWidget.value?.id !== props.widget.id) {
    return false;
  }

  return (
    tableFields.filter(field => selectedField.value?.id === field.id).length > 0
  );
});

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
  resetConditionHandler: (stateName: string) => {
    const skip = ["page_type"];
    if (skip.includes(stateName)) return false;
    return true;
  },
});

init();

[
  FormStyleFieldName.LABEL,
  FormStyleFieldName.TOOLTIP,
  FormStyleFieldName.CHECKBOX,
  FormStyleFieldName.RADIO_GROUP,
  FormStyleFieldName.ERROR,
  FormStyleFieldName.FIELD,
].forEach(styleName => {
  if (fields.value[styleName]) {
    return;
  }

  const initial = STYLING_FIELDS[styleName]();

  const stylingField = createCustomField(
    {
      ...initial.options,
    },
    props.widget.id,
    styleName,
    initial.title
  );

  widgetsStore.updateWidgetFields(props.widget, [
    ...props.widget.fields,
    stylingField,
  ]);
});

const amountStyles = computed<ElementStyle>(() => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: getFlexPosition(
      fields.value.table_amount.field.options.display.alignment
    ),
    gap: getPxValueFromNumber(
      fields.value.table_amount.field.options.display.distance
    ),
  };
});

const getWidth = (width: {
  type: ResizingType;
  value: ICellDimensionValue;
}): string => {
  if (width.type === ResizingType.FILL) {
    return "1fr";
  }

  return `${width.value.value}${width.value.type}`;
};

const dataWidth = computed<string>(() => {
  const width = fields.value.table_data.field.options.width;

  return getWidth(width);
});

const parametersWidth = computed<string>(() => {
  const width = fields.value.table_parameters.field.options.width;

  return getWidth(width);
});

const tableContainerVars = computed<ElementStyle>(() => {
  return getStylesAsVars(
    {
      "gap": getPxValueFromNumber(
        fields.value.table.field.options.displayMode.distanceBetweenItems
      ),
      "checkbox-size": getPxValueFromNumber(
        fields.value.table_checkboxes_settings.field.options.size
      ),
      "data-width": dataWidth.value,
      "parametrs-width": parametersWidth.value,
    },
    "table"
  );
});

const iconRefenceStyle = computed<ElementStyle>(() => {
  return {
    color: getColorFromHex(
      fields.value.table_reference.field.options.icon.color
    ) as string,
  };
});

const checkboxStyle = computed<ElementStyle>(() => {
  return getStylesAsVars(
    {
      ...getCornerRadiusStyle(
        fields.value.table_checkboxes_settings.field.options.cornerRadius
      ),
    },
    "checkbox"
  );
});

const referenceStyles = computed<ElementStyle>(() => {
  return getStylesAsVars(
    {
      position: getFlexPosition(
        fields.value.table_reference.field.options.alignment
      ),
    },
    "reference"
  );
});
</script>

<style lang="scss">
.transaction-history {
  &__data {
    display: grid;
    grid-auto-flow: column;
    gap: var(--table-gap);
  }

  &__parametrs {
    display: grid;
    grid-auto-flow: column;
    gap: var(--table-gap);
  }

  &__checkbox-container {
    .form-checkbox-button-field__checkbox {
      border-radius: (var(--checkbox-borderRadius));
    }
  }

  .racing-sports-table-cards {
    overflow: hidden;

    &__element {
      align-items: center;
    }
  }

  &__table {
    &--table.racing-sports-table-cards--is-grid {
      .racing-sports-table-cards__header {
        grid-template-areas: "checkbox date reference description transaction_type operation_amount status";
        grid-template-columns:
          var(--table-checkbox-size) calc(var(--table-data-width) * 0.35) calc(
            var(--table-data-width) * 0.65 - var(--table-checkbox-size) -
              var(--table-gap) * 2
          )
          1fr calc((var(--table-parametrs-width) - var(--table-gap) * 2) * 0.33)
          calc((var(--table-parametrs-width) - var(--table-gap) * 2) * 0.33)
          calc(
            (var(--table-parametrs-width) - var(--table-gap) * 2) * 0.33
          ) !important;
      }

      .racing-sports-table-cards__element {
        grid-template-areas: "checkbox description transaction_type";
        grid-template-columns: var(--table-data-width) 1fr var(
            --table-parametrs-width
          ) !important;

        div[data-name="date"],
        div[data-name="reference"],
        div[data-name="operation_amount"],
        div[data-name="status"],
        div[data-name="winning"] {
          display: none;
        }
      }
    }

    &--rows.racing-sports-table-cards--is-grid {
      .racing-sports-table-cards__header {
        grid-template-areas: "checkbox date description transaction_type";
        grid-template-columns:
          var(--table-checkbox-size) var(--table-data-width)
          1fr var(--table-parametrs-width) !important;

        div[data-name="reference"],
        div[data-name="operation_amount"],
        div[data-name="status"],
        div[data-name="winning"] {
          display: none;
        }
      }

      .racing-sports-table-cards__element {
        grid-template-areas: "checkbox description transaction_type";
        grid-template-columns:
          calc(
            var(--table-checkbox-size) + var(--table-data-width) +
              var(--table-gap)
          )
          1fr var(--table-parametrs-width);

        div[data-name="date"],
        div[data-name="reference"],
        div[data-name="operation_amount"],
        div[data-name="status"],
        div[data-name="winning"] {
          display: none;
        }
      }
    }

    &--wrap.racing-sports-table-cards--is-grid {
      .racing-sports-table-cards__header {
        grid-template-areas: "checkbox date . . reference" ". description . . ." ". transaction_type operation_amount status .";
        grid-template-columns: var(--table-checkbox-size) 1fr 1fr 1fr 1fr !important;

        div[data-name="reference"] {
          text-align: right !important;
        }
      }

      .racing-sports-table-cards__element {
        grid-template-areas: "checkbox" "description" "transaction_type";
        grid-template-columns: 1fr;

        div[data-name="date"],
        div[data-name="reference"],
        div[data-name="operation_amount"],
        div[data-name="status"],
        div[data-name="winning"] {
          display: none;
        }
      }
    }
  }

  &__data {
    align-items: center;
    &--table {
      grid-template-areas: "checkbox date reference";
      grid-template-columns: var(--table-checkbox-size) 35% calc(
          65% - var(--table-checkbox-size) - var(--table-gap) * 2
        ) !important;
    }

    &--rows {
      grid-template-areas: "checkbox date" "reference reference";
      grid-template-columns: var(--table-checkbox-size) 1fr;
    }

    &--wrap {
      grid-template-areas: "checkbox date reference";
      grid-template-columns:
        var(--table-checkbox-size) 1fr
        2fr;
    }
  }

  &__parametrs {
    align-items: center;
    &--table {
      grid-template-areas: "type amount status";
      grid-template-columns: 1fr 1fr 1fr;
    }

    &--rows {
      grid-template-areas: "type" "amount" "status";
    }

    &--wrap {
      grid-template-areas: "type amount status";
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__col-text {
    span {
      display: inline-block;
      vertical-align: middle;
      line-height: 1;

      &:first-child {
        margin-right: 4px;
      }
    }

    .common-icon {
      display: inline;
    }
  }

  &__status-text {
    text-decoration: inherit;
  }

  &__col-status {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    text-decoration: inherit;

    &--true {
      background-color: #52c41a;
    }

    &--false {
      background-color: #ff4d4f;
    }
  }

  &__checkbox-container {
    grid-area: checkbox;
  }

  &__date {
    grid-area: date;
  }

  &__reference {
    grid-area: reference;

    .basic-title-widget__content {
      display: flex;
      align-items: center;
      justify-content: var(--reference-position);
    }
  }

  &__cancel-button {
    justify-content: flex-start;
  }

  &__transaction-type {
    grid-area: type;
  }

  &__amount {
    grid-area: amount;
  }

  &__status {
    grid-area: status;
  }

  .racing-sports-table-cards__value {
    white-space: normal;
  }
}

.transaction-history {
  &--betting_history {
    .transaction-history__table {
      &--table.racing-sports-table-cards--is-grid {
        .racing-sports-table-cards__element {
          div[data-name="status"] {
            display: block;
          }
        }

        .transaction-history__parametrs {
          grid-template-areas: "status amount type";
        }
      }

      &--rows.racing-sports-table-cards--is-grid {
        .racing-sports-table-cards__header {
          div[data-name="status"] {
            display: block;
          }
        }

        .racing-sports-table-cards__element {
          div[data-name="status"] {
            display: block;
          }

          .transaction-history__parametrs {
            grid-template-areas: "status" "amount" "type";
          }
        }
      }

      &--wrap.racing-sports-table-cards--is-grid {
        .racing-sports-table-cards__header {
          div[data-name="status"] {
            display: block;
          }
        }

        .racing-sports-table-cards__element {
          div[data-name="status"] {
            display: block;
          }

          .transaction-history__parametrs {
            grid-template-areas: "status amount type";
            grid-template-columns: 1fr 1fr 1fr;
          }
        }
      }
    }
  }

  &--pending_withdrawals {
    .transaction-history__table {
      &--table.racing-sports-table-cards--is-grid {
        .racing-sports-table-cards__header {
          grid-template-areas: "checkbox date reference description operation_amount";
          grid-template-columns:
            var(--table-checkbox-size) calc(var(--table-data-width) * 0.35) calc(
              var(--table-data-width) * 0.65 - var(--table-checkbox-size) -
                var(--table-gap) * 2
            )
            1fr var(--table-parametrs-width) !important;
        }

        .racing-sports-table-cards__element {
          grid-template-areas: "checkbox description operation_amount";
          grid-template-columns: var(--table-data-width) 1fr var(
              --table-parametrs-width
            ) !important;

          div[data-name="operation_amount"] {
            display: block;
          }
        }

        .transaction-history__parametrs {
          grid-template-areas: "operation_amount";
        }
      }

      &--rows.racing-sports-table-cards--is-grid {
        div[data-name="operation_amount"] {
          display: block;
        }

        .racing-sports-table-cards__header {
          grid-template-areas: "checkbox date description operation_amount";
        }

        .racing-sports-table-cards__element {
          grid-template-areas: "checkbox description operation_amount";
        }

        .transaction-history__parametrs {
          grid-template-areas: "operation_amount";
        }
      }

      &--wrap.racing-sports-table-cards--is-grid {
        div[data-name="operation_amount"] {
          display: block;
        }

        .racing-sports-table-cards__header {
          grid-template-areas: "checkbox date . . reference" ". description . . ." ". operation_amount . . .";
        }

        .racing-sports-table-cards__element {
          grid-template-areas: "checkbox" "description" "operation_amount";
        }

        .transaction-history__parametrs {
          grid-template-areas: "operation_amount";
        }
      }
    }
  }
}
</style>
