import { useClipboard } from "@vueuse/core";
import { useNotification } from "@kyvg/vue3-notification";
import { uuidv7 } from "uuidv7";

import type { ICell } from "~~/models/grid.interface";
import type {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetsStore } from "~~/store/widgets";
import { useLocalisedField } from "~~/composables/fields/useLocalisedField";
import { BASE_MEDIA_URL } from "~~/constants";
import { useMetaStore } from "~~/store/meta";
import {
  createContentWidget,
  createWidgetFromContentElement,
} from "~~/assets/utils/widget";
import { deepCopy, generateId } from "~~/assets/utils";
import { configAppApi } from "~~/services/http";

const FILE_FIELD_TYPE = "FileUploadField";

const hasFieldsToUpload = (widget: IWidgetWithFields): boolean => {
  return (
    widget.fields.filter(field => {
      return field.type === FILE_FIELD_TYPE;
    }).length > 0
  );
};

export function useWidgetClipboardCopy() {
  const notification = useNotification();
  const widgetStore = useWidgetsStore();
  const metaStore = useMetaStore();
  const { toLocalised } = useLocalisedField();

  const { widgetsDetailsCache } = storeToRefs(widgetStore);

  const getWidgetCacheData = (staticId: string) => {
    return widgetsDetailsCache.value[metaStore.interfaceLang!]?.[staticId];
  };

  const copyWidget = async (
    cell: ICell,
    parentWidget: IWidgetWithFields | undefined,
    widgetIdx: number
  ) => {
    const list = parentWidget?.options?._children || cell.widgets;

    if (!list?.[widgetIdx]) {
      const errorMessage = `Widget with index "${widgetIdx}" in cell "${cell.cellId}" not found`;

      notification.notify({
        text: errorMessage,
        type: "error",
      });

      throw new Error(errorMessage);
    }

    const clipboard = useClipboard({ legacy: true });

    if (!clipboard.isSupported) {
      notification.notify({
        text: "Clipboard not supported in your browser",
        type: "error",
      });
    }

    await clipboard.copy(
      JSON.stringify({
        command: "copyWidget",
        data: list[widgetIdx],
      })
    );

    notification.notify("Copied to clipboard!");
  };

  const downloadFile = (fileUrl: string): Promise<Blob> => {
    return configAppApi
      .get(`/content-tools/proxy-media-resource?url=${fileUrl}`, {
        withCredentials: false,
        responseType: "blob",
      })
      .then(response => response.data);
  };

  const uploadImage = ({
    url,
    blob,
    widget,
    field,
  }: {
    url: string;
    blob: Blob;
    widget: IWidgetWithFields;
    field: IWidgetField;
  }): Promise<any> => {
    /* 
      Get file type from url
    */
    const filetype = url.split(".").pop();

    /* 
      Create file from blob with specified filename
    */
    const file = new File([blob], `${Date.now()}_file.${filetype}`);

    return widgetStore.contentImageUpload({
      file,
      widgetId: String(widget.static_id),
      attribute: field.name,
    });
  };

  type ImageValue = string | Record<string, string> | null;

  const reuploadLangsFileCopy = ({
    url,
    widget,
    field,
  }: {
    url: ImageValue;
    widget: IWidgetWithFields;
    field: IWidgetField;
  }): Promise<ImageValue> => {
    if (!url) {
      return Promise.resolve(null);
    }

    const currUrl = typeof url === "string" ? { _value: url } : url;
    const result: Record<string, string> = {};

    return Promise.all(
      Object.keys(currUrl).map(langName => {
        return (
          downloadFile(currUrl[langName])
            .then(blob => {
              return uploadImage({
                blob,
                url: currUrl[langName],
                widget,
                field,
              }).then(response => {
                result[langName] = response.data.path;
              });
            })
            /* 
              Download/Upload image was not successful
            */
            .catch(() => {
              result[langName] = `${BASE_MEDIA_URL()}/img.png`;
            })
        );
      })
    ).then(() => {
      /* 
        If only single value, there is no translation
      */
      if (result._value) {
        return result._value;
      }

      return result;
    });
  };

  const reuploadImage = (
    widget: IWidgetWithFields,
    field: IWidgetField
  ): Promise<IWidgetField> => {
    if (field.type !== FILE_FIELD_TYPE) {
      return Promise.resolve(field);
    }

    return new Promise((resolve): void => {
      reuploadLangsFileCopy({
        url: (field.value || field.default_value) as ImageValue,
        widget,
        field,
      }).then(result => {
        if (result) {
          field.value = result;
        }

        resolve(field);
      });
    });
  };

  const getWidgetFieldsWithDuplicatedFiles = async (
    widget: IWidgetWithFields
  ): Promise<IWidgetField[]> => {
    const results = (await Promise.allSettled(
      widget.fields.map(field => reuploadImage(widget, field))
    )) as PromiseFulfilledResult<IWidgetField>[];

    return results.map(result => result.value);
  };

  const getCorrectValue = (value: Record<string, string>): string => {
    for (const lang in value) {
      if (value[lang] && !value[lang].includes("undefined")) {
        return value[lang];
      }
    }

    return "";
  };

  const duplicateWidgetData = async (data: {
    widget: IWidgetWithFields;
    cell: ICell;
  }): Promise<IWidgetWithFields | null> => {
    const { widget, cell } = data;
    const newWidget = deepCopy(widget);
    newWidget.id = uuidv7();

    const hasCacheData = getWidgetCacheData(newWidget.static_id);

    if (!hasCacheData) {
      /*
        Force to fetch widget details by static id(use -1 for that)
      */
      await widgetStore.fetchWidgetDetails(-1, newWidget.static_id);
    }

    if (newWidget.options._children) {
      for (let i = 0; i < newWidget.options._children!.length; i++) {
        const child = newWidget.options._children![i];

        const result = await duplicateWidgetData({
          widget: {
            ...child,
            parentId: newWidget.id,
          },
          cell,
        });

        if (!result) {
          return null;
        }

        newWidget.options._children![i] = result;
      }
    }

    newWidget.fields = newWidget.fields.map(field => {
      if (field.type === "HtmlContentField") {
        if (field.value && typeof field.value === "object") {
          for (const lang in field.value) {
            const currValue = (field.value as Record<string, string>)[lang];

            if (currValue && currValue.includes("undefined")) {
              (field.value as Record<string, string>)[lang] = getCorrectValue(
                field.value as Record<string, string>
              );
            }
          }
        }
      }

      if (field.type === "TextInputField") {
        if (!field.value || Array.isArray(field.value)) {
          field.value = "Default";
        }
      }

      return toLocalised(
        {
          ...field,
          id: generateId(),
        },
        true
      );
    });

    const hasUploadFields = hasFieldsToUpload(newWidget);

    if (!hasUploadFields) {
      return newWidget;
    }

    const fields = await getWidgetFieldsWithDuplicatedFiles(newWidget).catch(
      () => {
        notification.notify({
          text: "Something went wrong. Please try again",
          type: "error",
        });
        return;
      }
    );

    if (!fields) {
      return null;
    }

    newWidget.fields = fields;

    const newGenerated = createWidgetFromContentElement(
      createContentWidget(newWidget as IWidgetWithFields, cell.cellId as string)
    );

    return newGenerated;
  };

  const pasteWidget = async (
    cell: ICell,
    parentWidget: IWidgetWithFields | undefined,
    widgetArrayIndex: number
  ) => {
    const list = parentWidget?.options?._children || cell.widgets;

    if (!list?.[widgetArrayIndex]) {
      const errorMessage = `Widget with index "${widgetArrayIndex}" in cell "${cell.cellId}" not found`;

      notification.notify({
        text: errorMessage,
        type: "error",
      });

      throw new Error(errorMessage);
    }

    const contentFromClipboard = await navigator.clipboard.readText();
    if (!contentFromClipboard) {
      return;
    }

    const parsedContent = JSON.parse(contentFromClipboard);

    if (!parsedContent.command && parsedContent.command !== "copyWidget") {
      notification.notify({
        text: "Wrong content format from clipboard",
        type: "error",
      });
      return;
    }

    const newWidget = await duplicateWidgetData({
      widget: parsedContent.data as IWidgetWithFields,
      cell,
    });

    if (!newWidget) {
      return;
    }

    widgetStore.replaceWidgetByAnotherWidget({
      cell,
      widgetArrayIndex,
      newWidget,
      parentWidget,
    });
  };

  return {
    copyWidget,
    pasteWidget,
  };
}
