<template>
  <div class="tags-tab">
    <div class="tags-tab__search-container">
      <CommonDropdownSearch
        :input-value="searchModel"
        :model-value="pageIdModel"
        :options="pages"
        :loading="loading"
        input-placeholder="Select page"
        label="Page"
        class="m-t-16"
        @update:input-value="updateSearchModel"
        @update:model-value="updatePageIdModel"
      />

      <a-input
        v-if="pageIdModel"
        v-model:value="searchTagsModel"
        placeholder="Search tag"
        class="tags-tab__search-input ant-input--has-suffix-clickable"
      >
        <template #suffix>
          <span class="common-search__icon">
            <CommonIcon
              name="ant-design:search-outlined"
              class="tags-tab__search-icon"
            />

            <span
              v-if="searchTagsModel"
              class="pointer tags-tab__search-icon"
              @click="searchTagsModel = ''"
            >
              <CommonIcon name="custom:close-field-icon" />
            </span>
          </span>
        </template>
      </a-input>
    </div>

    <div class="tags-tab__list p-l-16 p-r-16 m-t-16">
      <template v-if="!loading">
        <div v-if="!searchedTagsResult.length">
          <p class="text-center tags-tab__empty">No tags found</p>
        </div>

        <div
          v-for="tag in searchedTagsResult"
          :key="tag"
          class="tags-tab__item-wrapper"
          @click="handleTagClick(tag)"
        >
          <span class="tags-tab__item-text">
            {{ tag }}
          </span>

          <CommonIcon
            name="ant-design:copy-outlined"
            class="tags-tab__copy"
            @click.stop="handleCopyClick(tag)"
          />
        </div>
      </template>
    </div>
    <CommonSpinner v-if="loading" />
  </div>
</template>

<script lang="ts" setup>
import { useClipboard } from "@vueuse/core";
import { useNotification } from "@kyvg/vue3-notification";

import { useGridConfig } from "~~/store/grid";
import { useGlobalPages } from "~~/composables/widgets-config/useGlobalPages";
import { gatherWidgetsTags } from "~~/assets/utils";
import { usePagesStore } from "~~/store/pages";
import {
  DropdownSearchItem,
  OptionValue,
} from "~~/models/common/dropdown.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useIframePostMessage } from "~~/composables/useIframePostMessage";
import { useUiStore } from "~~/store/ui";

const props = defineProps<{
  visible: boolean;
}>();

defineEmits<{
  (e: "select-page"): void;
}>();

const gridStore = useGridConfig();
const pagesStore = usePagesStore();

const { pagesData, fetchAllPages } = useGlobalPages(toRef(true));

const { selectedPageId } = storeToRefs(pagesStore);

const isCurrentPage = computed(() => {
  return selectedPageId.value === pageIdModel.value;
});

const currentPageWidgetsTags = computed<string[]>(() => {
  return isCurrentPage.value || isAllPages.value
    ? gatherWidgetsTags(gridStore.widgetsGridLayout)
    : [];
});

const ALL_PAGES = "ALL_PAGES";

const pages = computed(() => {
  return Object.values(pagesData.value).reduce((acc, page, index) => {
    if (!index) {
      acc.push({
        label: "All pages",
        value: ALL_PAGES,
      });
    }

    if (page.id && page.name) {
      acc.push({
        label: page.name,
        value: page.id,
      });
    }
    return acc;
  }, [] as { label: string; value: OptionValue }[]);
});

const searchModel = ref<OptionValue>(null);
const updateSearchModel = (event: string) => {
  searchModel.value = event;

  if (!event) {
    pageIdModel.value = null;
  }
};

const pageIdModel = ref<OptionValue>(selectedPageId.value);
const isAllPages = computed(() => {
  return pageIdModel.value === ALL_PAGES;
});
const updatePageIdModel = (event: DropdownSearchItem) => {
  pageIdModel.value = event?.id || null;
};

const gatheredTags = computed<string[]>(() => {
  const pagesTags = Object.keys(pagesData.value).reduce(
    (acc: string[], pageId) => {
      if (pageId === pageIdModel.value || isAllPages.value) {
        const page = pagesData.value[pageId];
        const pageTags = page.options.tags || [];
        acc.push(...pageTags);
      }
      return acc;
    },
    []
  );

  const uniqPagesTags = new Set([
    ...pagesTags,
    ...currentPageWidgetsTags.value,
  ]);

  return Array.from(uniqPagesTags);
});

const searchTagsModel = ref<string>("");
const searchedTagsResult = computed<string[]>(() => {
  return gatheredTags.value.filter(tag => {
    const currValue = tag.toLocaleLowerCase();
    const searchLower = searchTagsModel.value.toLowerCase();

    return currValue.includes(searchLower);
  });
});

const loading = ref<boolean>(false);

const fetchData = async (): Promise<void> => {
  try {
    loading.value = true;
    await fetchAllPages();
    loading.value = false;
  } catch (e) {
    loading.value = false;
  }
};

const handleTagClick = async (tag: string) => {
  if (isCurrentPage.value) {
    selectWidget(tag);
    return;
  }

  if (pageIdModel.value) {
    const page = pagesStore.pages.find(item => {
      return item.id === pageIdModel.value;
    });

    if (page) {
      pagesStore.setSelectedPage(page);
      await pagesStore.fetchPageContent();
      selectWidget(tag);

      useIframePostMessage().sendPathChange(`/pages/${page?.id}/setup`);
    }
  }
};

const selectWidget = (tag: string) => {
  const widget = gridStore.pageWidgets.find(widget => {
    return widget.options._tag === tag;
  });

  if (widget) {
    const breakpoint = gridStore.findScreenByCellId(widget.cell_id);
    useUiStore().setScreen(breakpoint);
    useWidgetSettingsStore().selectWidget(widget);
  }
};

const clipboard = useClipboard({ legacy: true });
const notification = useNotification();

const handleCopyClick = async (tag: string): Promise<void> => {
  await clipboard.copy(tag);

  notification.notify("Copied to clipboard!");
};

watch(
  async () => props.visible,
  newValue => {
    if (!newValue) {
      return;
    }

    if (Object.keys(pagesData.value).length) {
      return;
    }

    fetchData();
  }
);
</script>

<style lang="scss">
.tags-tab {
  width: $propertybar-width;

  &__search-container {
    padding: 0 $space-m;
  }

  &__empty {
    font-style: italic;
    color: $c-grey-45;
    font-size: 14px;
    margin: 0;
  }

  &__search-input {
    margin: $space-m 0;
    @include flex(center, space-between, 4px);

    // .ant-input {
    //   padding-right: 18px !important;
    // }

    .ant-input-suffix {
      position: static;
      transform: none;
      user-select: auto;
      user-select: initial;
      pointer-events: all;
    }
  }

  .ant-input-affix-wrapper {
    border-radius: 6px;
  }

  &__list {
    overflow: auto;
    height: calc(100vh - 255px);
    width: 100%;
  }

  &__item-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__item-wrapper {
    @include flex(center, space-between, 0px);
    padding: $space-s;
    margin: 4px 0;
    border-radius: 8px;
    height: 40px;
    background-color: $c-grey-02;
    cursor: pointer;

    &:hover {
      background-color: $c-light-blue;
      color: $c-primary-base;
    }
  }

  &__search-icon {
    color: $c-grey-25;
  }

  &__copy {
    padding: 5px;
  }
}
</style>
