import { pipeSync } from "~~/helpers/pipe";
import type { IContentField, IPageContentWidget } from "~~/models/page.model";
import type {
  ICustomField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { FieldName } from "~~/models/widgets/widget.core/field-names.enum";
import {
  ContainerMode,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";
import {
  generateArrows,
  generatePagination,
} from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget/promo-bets-fenix-widget-css";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import { generateFlexibleImageCssString } from "../utils/form-helper-functions";
import { generateFlex, generateSize, generateWidth } from "../helpers";
import { prefillWithClasses } from "../utils/prefill-with-classes";
import { getFlexAlign } from "../../widget-settings";
import { getPxValueFromNumber } from "../..";

import {
  generateButtonCssString,
  generateButtonsGroupCssString,
} from "./card-widget-css";
import { generateCardFenixCssString } from "./card-fenix-widget-css";
import { generateGridLayoutCssString } from "./promotions-list-widet-css";

const generateSlidesCssString =
  (widget: IWidgetOptions) =>
  (cssString: string): string => {
    const items = widget.content["items"];
    const isSlider = widget.options.display.mode === ContainerMode.CAROUSEL;

    cssString += generateCssClassWithContent({
      className: widget.options._cssClass,
      content: generateWidth(100, "%") + generateDefaultStyles(widget.options),
    });

    cssString += generateCssClassWithContent({
      className: widget.options._skeletonCssClass,
      content: generateSize({
        width: widget.options.display.width,
        height: widget.options.display.height,
      }),
    });

    if (isSlider) {
      const flex = generateFlex({
        flex: "flex",
        align: getFlexAlign(widget.options.display.alignment)["align-items"],
        justify: "initial",
        gap: widget.options.display.gap.column,
      });
      cssString += generateCssClassWithContent({
        className: widget.options._wrapperCssClass,
        content: flex,
      });
    } else {
      // Adapt the grid object
      widget.options.gridSettings = {
        grid: widget.options.display.layout,
        minCardWidth: widget.options.display.width,
        gridFixedType: widget.options.display.grid.gridFixedType,
        maxCardsInRow: widget.options.display.grid.maxCardsInRow,
        minCardsInRow: widget.options.display.grid.minCardsInRow,
        gap: widget.options.display.gap,
      };

      cssString += generateGridLayoutCssString(
        widget,
        undefined,
        undefined,
        widget.options._wrapperCssClass
      )("");
    }

    (items.value as ICustomField[]).forEach(card => {
      card.options._cssClass = generateClassName("card");
      card.options._itemWrapperCssClass =
        generateClassName("card-item-wrapper");
      card.options._textContainerCssClass =
        generateClassName("card-text-group");

      const contentFields = card.fields?.reduce((acc, field) => {
        acc[field.name] = field;
        return acc;
      }, {} as Record<string, IContentField>);

      cssString += generateCardFenixCssString(
        {
          ...card.options,
          height: widget.options?.display.height,
        },
        contentFields!
      )("");

      if (isSlider) {
        const isFixed = widget.options.display.layout === ResizingType.FIXED;
        const visible = widget.options.display.slider.visible;
        const styles = [
          "flex-shrink: 0;",
          isFixed
            ? `width: ${getPxValueFromNumber(widget.options.display.width)};`
            : `width: calc((100% - (${visible} - 1) * ${getPxValueFromNumber(
                widget.options.display.gap.column
              )}) / 
            ${visible});`,
        ];
        cssString += generateCssClassWithContent({
          className: card.options._itemWrapperCssClass,
          content: styles.join(""),
        });
      }

      card.fields?.forEach(cardField => {
        cardField.options._cssClass = generateClassName(cardField.name);

        switch (cardField.name) {
          case FieldName.TITLE:
            cssString += generateStringWithStates(cardField, true)("");
            break;

          case FieldName.IMAGE:
            cssString += generateFlexibleImageCssString(cardField)("");
            break;

          case FieldName.DESCRIPTION_1:
            cssString += generateStringDefault(cardField)("");
            break;

          case FieldName.BUTTONS_GROUP:
            cssString += generateButtonsGroupCssString(cardField)("");
            break;

          case FieldName.BUTTON_1:
            cardField.options.iconSettings._cssClass =
              generateClassName("buttonIcon");
            cssString += generateButtonCssString(cardField)("");
            break;

          case FieldName.BUTTON_2:
            cardField.options.iconSettings._cssClass =
              generateClassName("buttonIcon");

            cssString += generateButtonCssString(cardField)("");
            break;
        }
      });
    });

    return cssString;
  };

export const generateCardsListWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName(widget.name);
  widget.options._skeletonCssClass = generateClassName("slider-skeleton");
  widget.options._wrapperCssClass = generateClassName("wrapper");

  const prefilled = prefillWithClasses(widget.content);

  prefilled.arrows.options._containerCssClass =
    generateClassName("arrowsContainer");
  prefilled.arrows.options.button._cssClass = generateClassName("arrowsButton");

  prefilled.pagination.options._cssClass = generateClassName("pagination");
  prefilled.pagination.options._dotsCssClass =
    generateClassName("paginationDots");
  return pipeSync<string>(
    // generateWidgetCssString(widget),
    generateSlidesCssString(widget),
    generateArrows(prefilled.arrows),
    generatePagination(prefilled.pagination)
  )("");
};
